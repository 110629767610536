<template>

</template>
<script>
    import { GetMode } from "@/api/api";
export default {
    data() {
        return {
            union_id:''
        }
    },
    created() {
        this.union_id = this.$route.query.union_id
        localStorage.setItem('union_id', this.union_id)
        this.getMode()
    },
    methods: {
        getMode(){
            let param = {
                union_id:this.union_id
            }
            GetMode(param).then(res=>{
                if(res.mode==4){
                    this.$router.push({path:'/guildWaterList?union_id='+this.union_id})
                }else{
                    this.$router.push({path:'/guildWaterForm?union_id='+this.union_id})
                }
            })
        }
    }
}
</script>